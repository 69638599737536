@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    /*--primary: 222.2 47.4% 11.2%;*/
    /*--primary-foreground: 210 40% 98%;*/
    /*trestle custom*/
    --primary: 220 41% 47%;
    --primary-foreground: 210 40% 98%;
    --primary-hover: 54 39% 38%;


    /*--secondary: 210 40% 96.1%;*/
    /*--secondary-foreground: 222.2 47.4% 11.2%;*/
    /*trestle custom*/
    --secondary: 38 92% 66%;
    --secondary-foreground: 217 33% 17%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;


    --destructive: 10 100% 55%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    /* Trestle Design System Color Palette */
    /* UI Primitives - Light */

    --ui-green-10: #289380;
    --ui-green-20: #53A999;
    --ui-green-30: #7EBEB3;
    --ui-green-40: #A9D4CC;
    --ui-green-50: #D4E9E6;

    --ui-teal-10: #47A1AB;
    --ui-teal-20: #6CB4BC;
    --ui-teal-30: #91C7CD;
    --ui-teal-40: #B5D9DD;
    --ui-teal-50: #DAECEE;

    --ui-blue-10: #4769AB;
    --ui-blue-20: #6C87BC;
    --ui-blue-30: #91A5CD;
    --ui-blue-40: #B5C3DD;
    --ui-blue-50: #DAE1EE;

    --ui-purple-10: #9F61BB;
    --ui-purple-20: #B581CC;
    --ui-purple-30: #C698DA;
    --ui-purple-40: #D5B6E3;
    --ui-purple-50: #E3D7E9;

    --ui-yellow-10: #F6AE2D;
    --ui-yellow-20: #F8BE57;
    --ui-yellow-30: #FACE81;
    --ui-yellow-40: #FBDFAB;
    --ui-yellow-50: #FDEFD5;

    --ui-red-10: #FF1A1A;
    --ui-red-20: #FF4848;
    --ui-red-30: #FF7676;
    --ui-red-40: #FFA3A3;
    --ui-red-50: #FFD1D1;

    /* These are here because some designs use dark colors in light mode */
    --ui-dark-blue-20: #3E5A8E;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;

    /* Trestle Design System Color Palette */
    /* UI Primitives - Dark */

    --ui-green-10: #289380;
    --ui-green-20: #267B6C;
    --ui-green-30: #236458;
    --ui-green-40: #214C43;
    --ui-green-50: #1E352F;

    --ui-teal-10: #47A1AB;
    --ui-teal-20: #3E878E;
    --ui-teal-30: #366C71;
    --ui-teal-40: #2D5255;
    --ui-teal-50: #253738;

    --ui-blue-10: #4769AB;
    --ui-blue-20: var(--ui-dark-blue-20);
    --ui-blue-30: #364B71;
    --ui-blue-40: #2D3B55;
    --ui-blue-50: #252C38;

    --ui-purple-10: #9F61BB;
    --ui-purple-20: #9055AB;
    --ui-purple-30: #79498E;
    --ui-purple-40: #613E70;
    --ui-purple-50: #452F4F;

    --ui-yellow-10: #F6AE2D;
    --ui-yellow-20: #CA9129;
    --ui-yellow-30: #9F7426;
    --ui-yellow-40: #735722;
    --ui-yellow-50: #483A1F;

    --ui-red-10: #FF1A1A;
    --ui-red-20: #D21B1A;
    --ui-red-30: #A41B1A;
    --ui-red-40: #771C1B;
    --ui-red-50: #491C1B;
  }
}

@layer base {
  * {
    @apply border-border;
    font-synthesis: none;
  }

  body {
    @apply bg-background text-foreground;
    overflow-y: scroll;
    /* Fallback for browsers that do not support scrollbar-gutter */
  }

  html {
    scrollbar-gutter: stable;
    /* Prevent layout shift caused by scrollbars */
  }
}